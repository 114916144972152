<template>
  <layout class="leaderboard" v-load="loading">
    <div class="row pb-4">
      <div class="col-md-3">
        <h2>Bimi Coin accounts</h2>
      </div>
      <div class="col-md-3">
        <multiselect v-model="filter.option" :multiple="true" deselect-label="Remove" selected-label="Press" track-by="name" label="name" placeholder="Search by employees" :options="employeeList" :searchable="true">
          <template slot="singleLabel" slot-scope="{ option }"> {{ option.name }} </template>
        </multiselect>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body p-0">
            <div class="table" v-if="accounts.length > 0">
              <b-table hover :items="accounts" :fields="fields" stacked="md" id="account-table">
                <template #cell(#)="data">
                  {{ data.index + 1 }}
                </template>
              </b-table>
            </div>
            <div v-else>
              <b-table :fields="fields" id="account-table" hover />
              <EmptyPage/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Multiselect from 'vue-multiselect';
import EmptyPage from '@/components/empty-page';
export default {
  name: "index",
  data () {
    return {
      filter: {
        option: []
      },
      fields: [
        {
          key: '#',
          label: '#'
        },
        {
          key: 'employee.name',
          label: 'Name'
        },
        {
          key: 'balance',
          label: 'Available to spend'
        }
      ]
    }
  },
  computed: {
    loading() {
      return this.$store.state.bimiAccounts.loading;
    },
    employeeList () {
      return this.$store.getters['employees/employeeListForSelect']
    },
    accounts () {
      return [...this.$store.state.bimiAccounts.accounts]
        .sort((a, b) => a.employee.name.localeCompare(b.employee.name))
    }
  },
  watch: {
    async 'filter.option' (val) {
      let query = this.constructQueryForFilter(val)
      await this.$store.dispatch('bimiAccounts/filterAccounts', query)
    }
  },
  components: {
    Multiselect,
    EmptyPage
  },
  methods: {
    constructQueryForFilter (arr) {
      let query = '';
      arr.forEach((item, idx) => {
        if (idx === 0) {
          query += `?users[]=${item.uid}`
        } else {
          query += `&users[]=${item.uid}`
        }
      })
      return query
    }
  },
  async mounted() {
    await this.$store.dispatch('bimiAccounts/getAccountData')
  }
}
</script>

<style scoped>

</style>
